.ModalGeneric {
    align-items: flex-start;
    background: rgba(0, 0, 0, 0.7);
    display: flex;
    height: 100%;
    justify-content: center;
    left: 0;
    overflow-x: scroll;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 99;
}

.ModalBox {
    background: white;
    border-radius: 1rem;
    display: block;
    padding: 1rem;
    width: 90%;
}

.ModalClose {
    border-radius: 4px;
    display: block;
    font-weight: 800;
    text-align: center;

    &:hover {
        background-color: #e1e0e0;
        cursor: pointer;
    }
}