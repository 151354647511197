@use "sass:map";

$small: 768px;
$medium: 1024px;
$large: 1280px;

$breakpoints: (
    small-above: $small,
    medium-above: $medium,
    large-above: $large,
);

@mixin respond-above($breakpoint) {
    @if map-has-key($breakpoints, $breakpoint) {
        $above-breakpoint-value: map.get($breakpoints, $breakpoint);

        @media (min-width: $above-breakpoint-value) {
            @content;
        }
    }
}

@mixin background-image($path) {
    background-image: url($path);
    background-position: center;
    background-repeat: no-repeat;
}

@mixin margin-pages {
    background-color: #F5F5F5;
    border-radius: 20px;
    margin: 10px;
    padding-bottom: 200px;
    padding: 10px;

    @include respond-above(small-above) {
        margin-left: 40px;
        margin-right: 40px;
    }
}

@import "./Images.scss";
@import "./Icons.scss";
@import "./Fonts.scss";
@import "./Colors.scss";