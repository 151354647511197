@import "../../layout/mixins.scss";

.Nfts {
    @include margin-pages;

    .image {
        margin: 5px;
        margin-bottom: 15px;

        .image-nft {
            height: 200px;
            background-position: center;
            background-size: cover;
            border-radius: 5px;

            @include respond-above(small-above) {
                height: 300px;
            }

        }
    }

    .description {
        @include RobotoBold;
        font-size: 16px;
        line-height: 26px;
        margin-left: 30px;

        p {
            @include RobotoLight;
            line-height: 32px;

            b {
                text-decoration: underline;
            }

            .margin {
                margin-left: 20px;
            }
        }

        &.text {
            @include RobotoLight;
        }

        &.link {
            display: inline-flex;

            span {
                color: $link-blue;
            }
        }

        &.below {
            margin-top: 40px;

            ul {
                @include RobotoLight;

                li a {
                    color: $link-blue;
                }
            }
        }

    }
}