@import "../../layout/mixins.scss";

.PhotoEntryContainer {
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    margin: 5px 2px;
    max-width: 350px;
    padding: 2px;
    box-shadow: $box-shadow;
    cursor: pointer;
    border: 1px solid white;

    &:hover {
        border: 1px solid $hover-links;
        background-color: $hover-links;
    }    

    @include respond-above(small-above) {
        margin: 25px 2px;
    }


    .entry {
        padding: 0 10px;
        // border: 1px solid red
    }

    .bottom {
        @include RobotoLight;
        line-height: 24px;
        margin-bottom: 25px;
    }

    .photoContainer {

        img {
            margin: 6px;
            border-radius: 4px;
        }
    }
    .titulo {
        @include RobotoBold;
        // border: 1px solid red;
        // font-weight: 600;
    }
    .description {
        // border: 1px solid red;
        color: $font-gray;
    }
}