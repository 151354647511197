@import "../../layout/mixins.scss";

.Copyright {
    @include margin-pages;

    .image {
        margin: 5px;
        margin-bottom: 15px;;

        .image-copyright {
            height: 200px;
            background-position: center;
            background-size: cover;
            border-radius: 5px;

            @include respond-above(small-above) {
                height: 300px;
            }

        }
    }

    .description {
        @include RobotoBold;
        font-size: 16px;
        line-height: 26px;
        margin-left: 30px;

        &.link span{
            color: $link-blue;
        }      
    }
}