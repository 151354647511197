@import "../../layout/mixins.scss";

.BigPhoto {
    .PhotoEntry {

        img {
            width: 100%;
            border-radius: 5px;
        }

        .PhotoNavigation {
            display: flex;
            min-height: 50px;
            justify-content: space-between;

            .nav {
                min-width: 50px;

                .icon {
                    background-size: contain;
                    cursor: pointer;
                    display: block;
                    height: 50px;
                    margin-right: 10px;
                    width: 50px;
                }

                h1 {
                    margin: 0;
                    font-size: 1em;

                    @include respond-above(small-above) {
                        font-size: 2em;
                    }
                }
            }
        }
    }

    .DescriptionCentered {
        padding: 10px 10%;

        p {
            line-height: 25px;
        }
    }

    .DescriptionSection {
        width: 100%;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;

        a {
            text-decoration: underline;
        }

        span.icon {
            height: 20px;
            width: 20px;
            background-size: contain;
            margin-right: 10px;
        }
    }

}