@import "../../layout/mixins.scss";

.RegistrationForm {
    @include margin-pages;

    & {
        padding: 0;
    }

    @include respond-above(small-above) {
        padding: 40px 0;
    }

    .RegistrationForm__placeholder {
        display: flex;
        justify-content: center;
    }
}