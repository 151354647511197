@import "./mixins.scss";

.Header {
    background-color: $background-color;
    border-bottom: 1px solid $mobile-buttons;
    display: flex;
    position: sticky;
    top: 0;
    z-index: 10;
}

.Header__section {
    display: flex;
    width: 100%;
}

.Header__icon {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    @include respond-above(small-above) {
        display: none;
    }

    .icon-burger {
        background-size: cover;
        cursor: pointer;
        display: block;
        height: 30px;
        width: 30px;
    }
}

.Header__logo_title {
    display: flex;
    justify-content: center;
    margin-left: 10px;

    h1 {
        @include LobsterRegular;
        color: $font-link;
        font-size: 26px;

        @include respond-above(small-above) {
            font-size: 42px;
            margin-top: 15px;
        }
    }
}

.Header__links {
    display: none;
    width: 100%;

    @include respond-above(small-above) {
        display: flex;
        flex-direction: column;
    }


}