// main images
.profile-small {
    @include background-image("../images/nicodottaphoto-photographer-uruguay-small.jpeg");
}

.image-contact {
    @include background-image("../images/contact.jpg");
}

.image-copyright {
    @include background-image("../images/derechos-autor.jpg");
}

.image-information {
    @include background-image("../images/profile-photo-nicodottaphoto.jpg");
}

.image-information {
    @include background-image("../images/profile-photo-nicodottaphoto.jpg");
}

.image-nft {
    @include background-image("../images/nft-nicodottaphoto.jpeg");
}



// images general
.image-001 {
    @include background-image("../images/home/foto-nicodottaphoto-001.jpeg");
}

.image-002 {
    @include background-image("../images/home/foto-nicodottaphoto-002.jpeg");
}

.image-003 {
    @include background-image("../images/home/foto-nicodottaphoto-003.jpeg");
}