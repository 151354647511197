@import "../../../layout/mixins.scss";

.Blog {
    padding: 10px;

    .MainListing {
        @include margin-pages;

        & {
            display: flex;
            flex-direction: column;
        }

        .BlogContent .BlogSidebar {
            width: 100%;
        }

        @include respond-above(small-above) {
            flex-direction: row;

            .BlogContent {
                width: 80%;
            }

            .BlogSidebar {
                width: 20%;
            }
        }

        .entry-post {
            border-bottom: 1px solid $light-gray;
            ;
            padding-left: 10px;
            margin-bottom: 40px;

            @include respond-above(small-above) {
                padding-left: 30px;
            }

            .post-preview {

                p {
                    line-height: 35px;
                }

                .thumb {
                    border-radius: 10px;
                    border: 1px solid $mobile-buttons;
                    margin-top: 20px;
                    padding: 10px;
                    text-align: center;


                    a img {
                        border-radius: 10px;
                        width: 100%;

                        @include respond-above(small-above) {
                            width: auto;
                        }
                    }

                    @include respond-above(small-above) {
                        text-align: unset;

                        a img {
                            border-radius: 10px;

                        }
                    }
                }

                .post-resume {
                    display: flex;
                    flex-direction: column-reverse;

                    @include respond-above(small-above) {
                        flex-direction: row;
                    }
                }
            }

            .post-read-more {
                @include RobotoBold;
                color: $link-blue;

                &:hover {
                    color: $link-gray;
                }
            }

            h1 a:hover {
                color: $link-blue;
            }
        }

        .post-full {
            display: flex;
            flex-direction: column;
            line-height: 33px;
            margin-bottom: 50px;

            .full-image {
                text-align: center;

                img {
                    border-radius: 10px;
                    width: 95%;
                }
            }
        }

        .tag-list {
            display: flex;
            margin-left: 20px;
            display: flex;
            flex-direction: column;

            @include respond-above(small-above) {
                flex-direction: row;
            }

            a .tag-list-entry {
                @include RobotoBold;
                padding: 0 10px 0 10px;

                &.blue {
                    color: $link-blue;
                }
            }

            .standar-text {
                padding-right: 10px;
            }
        }
    }
}