@import "../layout/mixins.scss";

.NavigationMenu {
    width: 100%;

    ul.format-desk {
        align-items: center;
        display: flex;
        justify-content: end;

        li {
            @include RobotoBold;
            list-style-type: none;
            padding: 0 5px;

            a {
                display: flex;
            }

            &.language {
                display: none;

                @include respond-above(small-above) {
                    border-left: 1px solid $mobile-buttons;
                    color: $link-gray;
                    cursor: pointer;
                    display: list-item;
                    font-size: 10px;
                }
            }

            &.content-creator {
                border-left: 1px solid $mobile-buttons;
            }
        }
    }

    ul.format-mobile {
        padding-left: 10px;

        li {
            @include RobotoBold;
            font-size: 23px;
            list-style-type: none;
            margin: 10px 0;
            border-bottom: 1px solid $hover-links;

            &.language {
                font-size: 15px;
                color: $link-gray;
                cursor: pointer;
            }
        }
    }

    .icon {
        background-size: cover;
        cursor: pointer;
        display: inline-block;
        height: 20px;
        width: 20px;
    }

    .boxContainer {
        background-color: #FFFFFF;
        // border-bottom-right-radius: 10px;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
        box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;
        border-top: 1px solid white;
        display: block;
        min-width: 150px;
        right: 47px;

        @include respond-above(small-above) {
            right: 47px;
            width: 150px;
            position: absolute;
        }

        ul.listMenu {
            margin: 0;
            padding: 0;

            li {
                padding-left: 8px;

                &:hover {
                    background-color: $mobile-buttons;
                    cursor: pointer;
                }
            }

        }
    }
}