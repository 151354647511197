@import "../../layout/mixins.scss";

.Photos {
    padding: 10px;
}

.Photos_collections {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    .Photos_collections__item {
        align-items: center;
        display: flex;
        flex-direction: column;
        min-height: 200px;
        justify-content: center;
        margin: 10px;
        width: 400px;
    }
}

.CollectionBox {
    border: 1px solid white;
    box-shadow: $box-shadow;
    display: flex;
    flex-direction: column;
    padding: 10px;
    width: 100%;

    &:hover {
        border: 1px solid $hover-links;
        background-color: $hover-links;
    }

    a {
        display: flex;
        flex-direction: column;
        overflow: hidden;

        img {
            object-fit: cover;
            object-position: center;

            @include respond-above(small-above) {
                height: 200px;
            }
        }
    }


    .CollectionBox_title {
        @include RobotoBold;
    }
}