@import "./mixins.scss";

.Body {

    .Home__linksMobile {
        padding: 10px;

        @include respond-above(small-above) {
            display: none;
        }

        .NavigationMenu {
            ul.format-desk {
                margin: 0;
                padding: 0;
                display: flex;
                justify-content: space-around;

                li {

                    border: 1px solid $mobile-buttons;
                    border-radius: 5px;
                }

            }

        }
    }

    .Suscription {
        display: flex;
        justify-content: center;
    }
}