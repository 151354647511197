@import "../../layout/mixins.scss";

.Information {
    @include margin-pages;

    .image {
        margin: 5px;
        margin-bottom: 15px;

        .image-information {
            height: 300px;
            background-position: center;
            background-size: cover;
            border-radius: 5px;

            @include respond-above(small-above) {
                height: 500px;
            }

        }
    }

}