@import "../../layout/mixins.scss";

.PhotosCollection {
    // border: 1px solid red;

    .Navegacion {
        @include RobotoBold;
        font-size: 16px;
        line-height: 26px;
        padding-left: 20px;


        @include respond-above(small-above) {
            font-size: 30px;
        }
    }

    a {
        color: gray;
    }

}

.ListContainer {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;

    @include respond-above(small-above) {
        flex-direction: row;
    }
}