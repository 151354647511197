// colors

$font-link: #1a263c;
$hover-links: #d0c9af42;
$font-gray: #25383C;
$background-color: white;
$link-blue: rgb(8, 8, 136);
$mobile-buttons: #f2f2f2;
$link-gray: #838383;
$green-military: #6d6d62;
$background-section-pages: #F5F5F5;
$light-gray: #cecece;
$logo-ndp-border: #c0c0c0;

//others
$box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;