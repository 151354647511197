@import "../layout/mixins.scss";

.MobileMenu {
    background-color: white;
    box-shadow: -9px -4px 17px rgba(0, 0, 0, 0.2);
    display: flex;
    flex-direction: column;
    height: 100%;
    padding-top: 30px;
    position: fixed;
    right: 0;
    top: 0;
    width: 270px;
    z-index: 10;

    .section {
        // border: 1px solid gray;
        display: flex;
        justify-content: flex-end;

        .CloseMenu {
            display: flex;
            padding-left: 10px;
            // border: 1px solid green;
            height: 40px;
            padding-right: 20px;
        }
    }

    .content {
        display: table-cell;
        height: 100%;
        padding: 10px;

        .SidebarContent {
            // border: 1px solid green;
            margin-top: 60px;
            padding-bottom: 10px;
            padding-left: 10px;
        }
    }

    .icon-close {
        background-size: contain;
        cursor: pointer;
        display: block;
        width: 27px;
    }

    .close-link {
        @include RobotoBold;
        padding-left: 2px;
        font-size: 18px;
    }

}