@import "../../layout/mixins.scss";

.Videos {
    padding: 10px;
}

.Videos_collections {
    // border: 1px solid green;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    .Videos_collections__item {
        align-items: center;
        border-radius: 5px;
        border: 1px solid white;
        box-shadow: $box-shadow;
        cursor: pointer;
        display: flex;
        flex-direction: column;
        margin: 10px;
        min-height: 200px;
        padding: 5px;
        width: 400px;

        &:hover {
            background-color: $hover-links;
            border: 1px solid $hover-links;
        }            
    }
}

.VideoCollectionBox {
    padding: 5px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 281px;

    @include respond-above(small-above) {
        width: auto;
    }

    .VideoCollectionBox_title {
        line-height: 26px;

        .titulo {
            @include RobotoBold;
            font-size: 16px;
            line-height: unset;
        }

    }

    .VideoCollectionBoxThumb {
        padding: 15px 0;

        @include respond-above(small-above) {
            padding: 25px 0;
        }

        .youtube-thumb {
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
            border-radius: 5px;
            height: 200px;
            width: 100%;


            @include respond-above(small-above) {
                background-size: 378px 270px;
                height: 200px;
                width: 378px;
            }

        }
    }
}