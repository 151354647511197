@import "../../layout/mixins.scss";

.YTVideo {
    padding: 10px;

    h1 {
        @include RobotoBold;
        font-size: 16px;
        line-height: 26px;

        @include respond-above(small-above) {
            font-size: 26px;
            line-height: 36px;
        }
    }

    .VideoContainer {
        position: relative;
        width: 100%;
        height: 0;
        padding-bottom: 56.25%;

        .vd {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            box-shadow: $box-shadow;
        }
    }

    .Description {
        display: flex;
        flex-direction: column;

        @include respond-above(small-above) {
            flex-direction: row;
        }

        .DescriptionSection {
            width: 100%;
            text-align: center;
            display: flex;
            justify-content: center;
            align-items: center;

            a {
                text-decoration: underline;
            }

            span.icon {
                height: 20px;
                width: 20px;
                background-size: contain;
                margin-right: 10px;
            }
        }
    }
}