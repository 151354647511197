@import "../../layout/mixins.scss";

.Pagination {
    align-items: center;
    border-top: 2px solid $hover-links;
    display: flex;
    justify-content: center;
    margin-bottom: 20px;;
    margin-top: 25px;

    .icon {
        background-size: cover;
        cursor: pointer;
        display: inline-block;
        height: 20px;
        width: 20px;

        @include respond-above(small-above) {
            height: 15px;
            width: 15px;
        }

        &:hover {
            background-color: $hover-links;
            border-radius: 3px;
        }

        &.disble {
            opacity: 0.1;
            cursor: auto;
        }
    }

    span {
        padding: 0 10px;
    }

    .text {
        font-size: 18px;
    }
}