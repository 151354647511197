@import "./mixins.scss";

.Footer {
    border-top: 1px solid $mobile-buttons;
    display: flex;
}

.Footer__links {
    align-items: center;
    display: flex;
    justify-content: center;
    width: 100%;

    @include respond-above(medium-above) {
        align-items: end;
        justify-content: end;
    }

    .hideOnMobile {
        display: none;

        @include respond-above(small-above) {
            display: inline-flex;
        }
    }
}

.Footer__links ul {
    li {
        display: inline-flex;
        margin-right: 10px;
    }
}


.Footer__ndp {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 20px;

    .logo {
        background-size: contain;
        border-radius: 50%;
        border: 1px solid $logo-ndp-border;
        display: block;
        height: 30px;
        width: 30px;

        @include respond-above(small-above) {
            height: 40px;
            width: 40px;
        }
    }
}

.cookie__consent {
    background-color: #2B373B;
    line-height: 14px;

    @include respond-above(small-above) {
        line-height: 30px;
    }

    .cookie__button_container {
        display: flex;
        justify-content: center;
        width: 100%;

        @include respond-above(small-above) {
            width: unset;
        }
    }

    .cookie__button {
        border-radius: 8px;
        color: #4e503b;
        font-size: 20px;
        height: 80px;
        width: 300px;

        @include respond-above(small-above) {
            height: 60px;
            width: 200px;
        }
    }
}