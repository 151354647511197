@import "../../layout/mixins.scss";

.Brands {
    @include margin-pages;

    .description {
        @include RobotoBold;
        font-size: 16px;
        line-height: 26px;
        margin-left: 30px;

        p {
            @include RobotoLight;
            line-height: 32px;

            b {
                text-decoration: underline;
            }

            .margin {
                margin-left: 20px;
            }
        }


    }
}