@use "./layout/mixins.scss" as *;

BODY {
    @include RobotoLight;
    background-color: $background-color;
    color: #111111;
    font-weight: normal;
    margin: 0 auto;
    max-width: 1440px;
    padding: 1em;
}

H2.title-2 {
    color: $font-link;
    font-style: normal;
    font-weight: bold;
}

h1 {
    color: $font-link;
    font-style: normal;
    font-weight: bold;
}

.paragraph-1 {
    color: #070c15;
}

a,
a:visited {
    color: $font-link;
    text-decoration: none;
}

a:hover,
a:visited:hover {
    color: gray;
    text-decoration: none;
}

.standar-icon {
    height: 20px;
    width: 20px;
    background-size: contain;
    margin-right: 10px;
}  

.standar-text {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
}