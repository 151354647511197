// generic icons
.icon-burger {
    @include background-image("../layout/icons/icon-burger.svg");
}

.icon-close {
    @include background-image("../layout/icons/icon-close.svg");
}

.icon-player {
    @include background-image("../layout/icons/icon-player.svg");
}

.icon-profile {
    @include background-image("../layout/icons/icon-profile.svg");
}


.icon-arrow-left {
    @include background-image("../layout/icons/incon-arrow-left.svg");
}

.icon-arrow-right {
    @include background-image("../layout/icons/incon-arrow-right.svg");
}

//social
.icon-social-facebook {
    @include background-image("../layout/icons/icon-social-facebook.svg");
}

.icon-social-linkedin {
    @include background-image("../layout/icons/icon-social-linkedin.svg");
}

.icon-social-whatsapp {
    @include background-image("../layout/icons/icon-social-whatsapp.svg");
}

.icon-social-x {
    @include background-image("../layout/icons/icon-social-x.svg");
}


//logo
.img-logo-ndp {
    @include background-image("../images/home/logo-nicodottaphoto.jpg");
}