.ContextPhotoMenu {
    align-items: center;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    height: 100%;
    justify-content: center;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 100;
}

.Message {
    background-color: white;
    border-radius: 10px;
    border: 3px solid #c0c0c0;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    max-width: 500px;
    min-width: 200px;
    padding: 10px;
    z-index: 101;
}

.AcceptMessage {
    border-radius: 4px;
    display: block;
    font-weight: 800;
    text-align: center;

    &:hover {
        background-color: #e1e0e0;
        cursor: pointer;
    }
}