@import "../../layout/mixins.scss";

.Home {
    // border: 1px solid rgb(215, 211, 211);
    display: flex;
    flex-direction: column;
}

// .Home__section {
//     // border: 3px solid gray;
//     width: 100%;

//     &.intro {
//         padding-top: 20px;
//         padding-bottom: 20px;
//         padding-left: 10px;
//         padding-right: 10px;
//     }
// }

.ImageHome {
    background-size: contain;
    height: 0;
    padding-top: 71.50%;
    position: relative;
    width: 100%;
    /* (img-height / img-width * container-width) */
    /* (1430 / 2000 * 100) */
}

.HomeDescription {
    position: absolute;
    display: none;

    @include respond-above(small-above) {
        // border: 1px solid yellow;
        display: block;
        font-size: 12px;
        font-weight: 700;
        left: 42px;
        line-height: 23px;
        top: 20%;
        width: 33%;
    }

    @include respond-above(medium-above) {
        // border: 1px solid red;
        line-height: 32px;
        font-size: 13px;
    }

    @include respond-above(large-above) {
        // border: 1px solid green;
        left: 83px;
    }

    h2.title-2 {
        @include respond-above(large-above) {
            font-size: 28px;
        }
    }

    .paragraph-1 {
        @include respond-above(large-above) {
            font-size: 18px;
        }
    }
}

.Home__presentation {
    min-height: 744px;

    @include respond-above(small-above) {
        min-height: 600px;
    }
}

.Home__presentation-image {
    @include background-image("../images/home/portada-960.jpg");
    background-position: top !important;
    background-size: cover;
    height: 313px;
    padding-top: 123.5%;
    position: relative;
    width: 100%;

    @include respond-above(small-above) {
        @include background-image("../images/home/portada-big.jpg");
        height: 600px;
        padding-top: 0;
    }
}

.Home__mobile_text {
    display: block;
    padding: 10px;

    @include respond-above(small-above) {
        display: none;
    }

    .paragraph-1 {
        line-height: 25px;
    }
}