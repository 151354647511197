@font-face {
    font-family: 'Roboto Light';
    src: url("fonts/Roboto-Light.ttf") format("truetype");
}

@font-face {
    font-family: 'Roboto Bold';
    src: url("fonts/Roboto-Bold.ttf") format("truetype");
}

@font-face {
    font-family: 'Lobster Regular';
    src: url("fonts/Lobster-Regular.ttf") format("truetype");
}

@mixin RobotoLight {
    font-family: 'Roboto Light', serif;
    font-style: normal;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 42px;
}

@mixin RobotoBold {
    font-family: 'Roboto Bold', serif;
    font-style: normal;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 42px;
}

@mixin LobsterRegular {
    font-family: 'Lobster Regular', serif;
    font-style: normal;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 42px;
}