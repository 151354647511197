@import "../../layout/mixins.scss";

.InteralPageLinks {
    @include RobotoBold;
    font-size: 16px;
    line-height: 26px;
    margin-left: 10px;
    margin-top: 40px;

    @include respond-above(small-above) {
        margin-left: 40px;
    }

    ul {
        @include RobotoLight;
        margin: 0;
        padding-left: 2px;
        list-style-type: none;

        @include respond-above(small-above) {
            padding-left: 10px;
        }

        li a {
            display: block;
            color: $link-blue;
        }
    }

}