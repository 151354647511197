@use "../../../layout/mixins.scss" as *;

.BlogSideBar {
    padding: 6px;

    .title {
        padding-bottom: 5px;
    }

    .List__container {
        border-left: 1px solid $light-gray;
        display: flex;
        flex-direction: column;
        margin-bottom: 50px;
        padding-bottom: 50px;
        padding-left: 20px;
        padding-top: 20px;

        a {
            @include RobotoBold;
            display: flex;
            min-height: 35px;

            .icon {
                background-size: contain;
                cursor: pointer;
                display: block;
                margin-right: 10px;
                width: 20px;
            }

            .sidebar-tag-list-entry {
                display: block;
                width: 100%;
            }
        }

    }

    ul.LatestList {
        border-left: 1px solid $light-gray;
        list-style-type: none;
        margin-bottom: 50px;
        padding-bottom: 50px;
        padding-left: 20px;
        padding-top: 20px;

        li span.link {
            @include RobotoBold;
            line-height: 29px;
            margin-bottom: 15px;
        }

    }

}